import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home')
  }, {
    path: '/newMedia',
    name: 'newMedia',
    component: () => import('@/views/newMedia')
  }, {
    path: '/product',
    name: 'product',
    component: () => import('@/views/product')
  },
  // 产品详情
  {
    path: '/product-details',
    name: 'product-details',
    component: () => import('@/views/product-details')
  }, {
    path: '/newsPages',
    name: 'newsPages',
    component: () => import('@/views/newsPages')
  }, {
    path: '/news-details',
    name: 'news-details',
    component: () => import('@/views/news-details')
  }, {
    path: '/activityPlanning',
    name: 'activityPlanning',
    component: () => import('@/views/activityPlanning')
  }, {
    path: '/joinPages',
    name: 'joinPages',
    component: () => import('@/views/joinPages')
  }, {
    path: '/joinList',
    name: 'joinList',
    component: () => import('@/views/joinList')
  }, {
    path: '/joinDetails',
    name: 'joinDetails',
    component: () => import('@/views/joinDetails')
  }, {
    path: '/company',
    name: 'company',
    component: () => import('@/views/company')
  }, {
    path: '/online-activity',
    name: 'online-activity',
    component: () => import('@/views/online-activity')
  }, {
    path: '/online-details',
    name: 'online-details',
    component: () => import('@/views/online-details')
  }, {
    path: '/tweetPages',
    name: 'tweetPages',
    component: () => import('@/views/tweetPages')
  }, {
    path: '/customerCase',
    name: 'customerCase',
    component: () => import('@/views/customerCase')
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
});
export default router
